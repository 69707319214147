import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import React, { useLayoutEffect, useRef } from 'react'
import styled from 'styled-components';
import Accordion from '../Accordion';


const Section = styled.section`
min-height: 100vh;
height: auto;
width: 100vw;
background-color: ${props => props.theme.text};
position: relative;
color: ${(props) => props.theme.body};
overflow: hidden;


display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`
const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: uppercase;
  color: ${(props) => props.theme.body};
  
  margin: 1rem auto;
  border-bottom: 2px solid ${(props) => props.theme.carouselColor};
  width: fit-content;

  @media (max-width: 48em){
  font-size: ${(props) => props.theme.fontxl};

  }
`;

const Container = styled.div`
width: 75%;
margin: 2rem auto;

display: flex;
justify-content: space-between;
align-content: center;

@media (max-width: 64em){
  width: 80%;
  }
  @media (max-width: 48em){
  width: 90%;
  flex-direction: column;

  &>*:last-child{
    &>*:first-child{

    margin-top: 0;
}

  }
  }
`
const Box = styled.div`
width: 45%;
@media (max-width: 64em){
  width: 90%;
  align-self: center;
  }

`

const Faq = () => {

  const ref = useRef(null);
  gsap.registerPlugin(ScrollTrigger);
  useLayoutEffect(() => {

    let element = ref.current;

    ScrollTrigger.create({
      trigger: element,
      start: 'bottom bottom',
      end: 'bottom top',
      pin: true,
      pinSpacing: false,
      scrub: 1,
      // markers:true,
    })

    return () => {
      ScrollTrigger.kill();
    };
  }, [])

  return (
    <Section ref={ref} id="faq">
      <Title>Faq</Title>

      <Container>

        <Box>
          <Accordion ScrollTrigger={ScrollTrigger} title="DE CE ZOMBIE?" >
            Decizia de a alege Zombie este emoțională, deoarece ca începător în acest mediu antreprenorial, fără ajutor, ajungi să mergi ca un zombie (foarte obosit și fără nicio direcție).
          </Accordion>
          <Accordion ScrollTrigger={ScrollTrigger} title="DE CE ESTE CONSIDERATĂ ELITĂ?" >
            Deoarece această comunitate va lucra împreuna la obiectivele lor. Fiind o comunitate invincibilă, din care vor face parte doar cei care își doresc cu adevarat, locurile fiind limitate.
          </Accordion>
          <Accordion ScrollTrigger={ScrollTrigger} title="CUM ÎMI PRIMESC REDEVENȚELE?" >
            În calitate de entitate juridică, vei încasa partea care ți se cuvine, doar după încheierea anului fisca. Se va face sub forma de transfer în portofelul tău virtual, în care deții NFT-ul.
          </Accordion>
        </Box>
        <Box>
          {/* <Accordion ScrollTrigger={ScrollTrigger} title="DE CE SUNT FINANȚATE DOAR CELE MAI INOVATIVE STARTUP-URI?" >
            Fiind un proiect la început de drum, nu putem acorda acest beneficiu tuturor, deoarece nu ar fi sustenabil și ne dorim ca acest proiect să se dezvolte și ulterior să oferim acest beneficiu mai multor firme.
          </Accordion> */}
          <Accordion ScrollTrigger={ScrollTrigger} title="DE CE SUNT FOLOSITE PENTRU MARKETING FONDURILE DIN PRESALE?" >
            Deoarece workcy.ro este principala sursă de venit pentru tot proiectul, și doar investind în marketing acest proiect poate fi sustenabil pe termen lung.
          </Accordion>
          <Accordion ScrollTrigger={ScrollTrigger} title="VOR FI DEZVOLTATE SI ALTE PROIECTE ÎN VIITOR?" >
            Desigur! La aceste proiecte vor avea prioritate cei care fac parte din Elită, ulterior vor deveni publice.
          </Accordion>
        </Box>
      </Container>
    </Section>
  )
}

export default Faq