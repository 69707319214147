import React, { lazy, Suspense } from 'react'
import styled, { ThemeProvider } from 'styled-components'
// import Carousel from '../Carousel'
import Button from '../Button'
import { dark } from '../../styles/Themes';
import Loading from '../Loading';


const Carousel = lazy(() => import("../Carousel"));

const Section = styled.section`
min-height: 100vh;
width: 100%;
background-color: ${props => props.theme.text};
display: flex;
justify-content: center;
align-items: center;
position: relative;
overflow: hidden;

`
const Container = styled.div`
width: 75%;
margin: 0 auto;
/* background-color: lightblue; */

display: flex;
justify-content: center;
align-items: center;
@media (max-width: 70em){
  width: 85%;
}

@media (max-width: 64em){
  width: 100%;
  flex-direction: column;

  &>*:last-child{
    width: 80%;
  }
}
@media (max-width: 40em){
  

  &>*:last-child{
    width: 90%;
  }
}
`
const Box = styled.div`
width: 50%;
height: 100%;
min-height: 60vh;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

@media (max-width: 40em){
  min-height: 50vh;
}
`

const Title = styled.h2`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.body};
  align-self: flex-start;
  width: 80%;
margin: 0 auto;

@media (max-width: 64em){
  width: 100%;
  text-align:center;
}
@media (max-width: 40em){
  font-size: ${(props) => props.theme.fontxl};

}
@media (max-width: 30em){
  font-size: ${(props) => props.theme.fontlg};

}
`
const SubText = styled.p`
  font-size: ${(props) => props.theme.fontlg};
  color: ${(props) => props.theme.body};
  align-self: flex-start;
  width: 80%;
margin: 1rem auto;
font-weight:400;
@media (max-width: 64em){
  width: 100%;
  text-align:center;
  font-size: ${(props) => props.theme.fontmd};

}
@media (max-width: 40em){
  font-size: ${(props) => props.theme.fontmd};

}
@media (max-width: 30em){
  font-size: ${(props) => props.theme.fontsm};

}

`
const SubTextLight = styled.p`
  font-size: ${(props) => props.theme.fontmd};
  color: ${(props) => `rgba(${props.theme.bodyRgba},0.6)`};
  align-self: flex-start;
  width: 80%;
margin: 1rem auto;
font-weight:400;

@media (max-width: 64em){
  width: 100%;
  text-align:center;
  font-size: ${(props) => props.theme.fontsm};

}
@media (max-width: 40em){
  font-size: ${(props) => props.theme.fontsm};

}
@media (max-width: 30em){
  font-size: ${(props) => props.theme.fontxs};

}

`
const ButtonContainer = styled.div`
 width: 80%;
 margin: 1rem auto;
 display: flex;
  align-self: flex-start;

  @media (max-width: 64em){
width: 100%;

button{
  margin: 0 auto;
}
}

`

const About = () => {
  return (
    <Section id="about">
      <Container>
        <Box>
          <Suspense fallback={<Loading />}>
            <Carousel /> </Suspense> </Box>
        <Box>
          <Title>
            Bun venit la <br /> Zombie Elite.
          </Title>

          <SubText>
            Ce este Zombie Elite?
          </SubText>
          <SubTextLight>
            <p>Zombie Elite este un proiect NFT unic, creat pe blockchain-ul Elrond, care se concentrează pe a forma o elită de 317 antreprenori din România. Acești antreprenori vor lucra împreună pentru a dezvolta și a creste afaceri. Proiectul se bazează pe ideea că prin colaborare și sprijin reciproc, antreprenorii pot atinge performanțe mai mari decât dacă ar lucra independent.</p>
            <br></br>
            <p>Proiectul NFT va permite antreprenorilor să devină membri ai acestei elite prin achiziționarea unui NFT. Acest NFT va oferi acces la o serie de beneficii exclusive, cum ar fi oportunități de a face parte din proiecte de afaceri comune, acces la resurse și informații valoroase, precum și oportunități de networking cu alți antreprenori din această elită.</p>
            <br></br>
            <p>Zombie Elite va fi un spațiu unde antreprenorii pot împărtăși idei, pot colabora la proiecte și pot învăța unii de la alții. Prin această colaborare și sprijin reciproc, ne dorim să ajutăm antreprenorii să-și atingă obiectivele lor de afaceri și să-și dezvolte afacerile într-un mod mai eficient.</p>
            <br></br>
            <p>In plus, prin intermediul acestui proiect NFT, antreprenorii vor putea sa isi promoveze afacerea si sa isi atraga noi clienti prin intermediul unor oportunitati unice de promovare si prin intermediul unei comunitati active si susținătoare.</p>
            <br></br>
            <p>In sfarsit, Zombie Elite este un proiect unic care ofera antreprenorilor din Romania oportunități unice de a colabora, a învăța și a se dezvolta împreună, prin intermediul unui proiect NFT creat pe blockchain-ul Elrond</p>
          </SubTextLight>


          <SubText>
            Ce probleme rezolvă?
          </SubText>
          <SubTextLight>



            <p>Proiectul nostru, <a href="https://workcy.ro">Workcy.ro</a>, este una dintre cele mai inovatoare platforme din Romania, care se concentrează pe a oferi sprijin antreprenorilor care prestează servicii și care întâmpină dificultăți în găsirea de noi clienți. Statisticile arată că în România, 9 din 10 firme dau faliment în primul an de activitate, datorită limitărilor în atragerea de clienți.</p>
            <br></br>
            <p>Pentru a ajuta antreprenorii să rezolve acest obstacol major, am creat platforma <a href="https://workcy.ro">Workcy.ro</a>, care se concentrează pe a oferi un mediu propice pentru a atrage noi clienți și a-și promova serviciile. Platforma noastră se bazează pe un sistem de recomandare, care permite clienților să găsească ușor și rapid serviciile de care au nevoie. De asemenea, ne concentrăm atenția asupra antreprenorilor începători, care pot intra în culisele platformei și pot vedea abordările și strategiile de marketing utilizate de antreprenorii de succes.</p>
            <br></br>
            <p>Pentru a oferi oportunități suplimentare de promovare, vom avea o zonă dedicată reclamelor, unde antreprenorii din elită isi pot promova afacerile beneficiind de anumite discount-uri față de cei care nu fac parte din această elită. Acest lucru va oferi oportunități unice și rare pentru antreprenorii care doresc să se promoveze pe platforma noastră și să atragă noi clienți.</p>
            <br></br>
            <p>In plus, vom oferi si servicii de consultanta pentru antreprenori, prin care acestia pot invata cum sa-si imbunatateasca abordarile de marketing si cum sa identifice si sa atraga noi clienti.</p>
            <br></br>
            <p>In final, platforma noastra <a href="https://workcy.ro">Workcy.ro</a> este un instrument esential pentru antreprenorii care doresc sa-si dezvolte afacerea si sa atraga noi clienti, si vom continua sa dezvoltam si sa imbunatatim aceasta platforma pentru a raspunde nevoilor si cerintelor antreprenorilor din Romania.</p>

          </SubTextLight>

          <SubText>
            Ce beneficii ai ca Membru al Elitei?
          </SubText>
          <SubTextLight>
            <p>1. Vei face parte dintr-o armată de antreprenori pasionați.</p>

            <p>2. Gratuitate pe viata în platforma <a href="https://workcy.ro">Workcy.ro</a>.</p>

            <p>3. Poți intra în culisele <a href="https://workcy.ro">Workcy.ro</a>.</p>

            <p>4. Acces la proiectele viitore, din faza incipientă.</p>

            <p>5. Vei primi drept reward fondurile strânse din reclamele rulate pe platformă.</p>
          </SubTextLight>

          <SubText>
            Cum poți intra în elită?
          </SubText>
          <SubTextLight>
            Poți face parte din elita doar achiziționând minim un NFT din colecția Zombie Elite.
          </SubTextLight>

          <ButtonContainer>
            <ThemeProvider theme={dark}>
              <Button text="JOIN OUR DISCORD" link="https://discord.gg/qsX63k8N7x" />
            </ThemeProvider>
          </ButtonContainer>
        </Box>
      </Container>
    </Section>
  )
}

export default About